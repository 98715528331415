var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center mb-3 mb-md-0",attrs:{"cols":"12","sm":"6"}},[_c('img',{staticClass:"mr-4",attrs:{"src":_vm.item.product.mediaURL,"height":"65px","width":"65px","alt":_vm.item.product.name,"onerror":"this.onerror=null;this.src='/no-icon.png'"}}),_c('div',{staticClass:"d-flex flex-column default--text"},[_c('span',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.name))]),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.item.product.shortDescr))]),(
          _vm.item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
            _vm.item.product.productInfos.PACKAGE_DESCR
        )?_c('span',{staticClass:"weight-unit font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.productInfos.PACKAGE_DESCR)+" ")]):_vm._e(),(_vm.itemOption)?_c('div',{staticClass:"product-item-info text-body-2"},[_c('i',[_vm._v(_vm._s(_vm.itemOption))])]):_vm._e(),(
          _vm.itemAdjustment &&
            _vm.itemAdjustment.userGiftCertificateId &&
            _vm.itemAdjustment.giftCertificate
        )?_c('div',{staticClass:"cart-item-info promo--text"},[_c('em',[_vm._v(_vm._s(_vm.itemAdjustment.giftCertificate.name))])]):_vm._e()])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2"}},[_c('OrderItemPrice',{attrs:{"item":_vm.item}})],1),(!_vm.modify)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2"}},[_c('OrderDetailQuantity',{attrs:{"item":_vm.item}})],1):_vm._e(),(_vm.modify)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2"}},[_c('ProductQty',{attrs:{"product":_vm.item.product,"item":_vm.item,"modify":true},on:{"updatePlus":function($event){return _vm.updatePlus()},"updateMinus":function($event){return _vm.updateMinus()}}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2","offset-sm":"0"}},[_c('strong',{staticClass:"gross-total primary--text"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")])]),(_vm.modify)?_c('v-col',{staticClass:"warning-cnt d-flex align-center mb-3 mb-md-0 flex-start justify-start w-100",attrs:{"cols":"6","sm":"6"}},[(_vm.showError)?_c('v-icon',[_vm._v("icon-warning")]):_vm._e()],1):_vm._e(),(_vm.modify)?_c('v-col',{staticClass:"d-flex align-center mb-3 mb-md-0 flex-end justify-end w-100",attrs:{"cols":"6","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDialogSubs(_vm.item)}}},[_c('span',[_vm._v("Replace Item")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }