<template>
  <v-row no-gutters align="center">
    <v-col cols="12" sm="6" class="d-flex align-center mb-3 mb-md-0">
      <img
        :src="item.product.mediaURL"
        class="mr-4"
        height="65px"
        width="65px"
        :alt="item.product.name"
        onerror="this.onerror=null;this.src='/no-icon.png'"
      />
      <div class="d-flex flex-column default--text">
        <span class="text-body-2 font-weight-bold">
          {{ item.product.name }}</span
        >
        <span class="text-caption">{{ item.product.shortDescr }}</span>
        <span
          class="weight-unit font-weight-bold"
          v-if="
            item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
              item.product.productInfos.PACKAGE_DESCR
          "
        >
          {{ item.product.productInfos.PACKAGE_DESCR }}
        </span>
        <div class="product-item-info text-body-2" v-if="itemOption">
          <i>{{ itemOption }}</i>
        </div>
        <div
          v-if="
            itemAdjustment &&
              itemAdjustment.userGiftCertificateId &&
              itemAdjustment.giftCertificate
          "
          class="cart-item-info promo--text"
        >
          <em>{{ itemAdjustment.giftCertificate.name }}</em>
        </div>
      </div>
    </v-col>
    <v-col cols="4" sm="2" class="d-flex justify-center">
      <OrderItemPrice :item="item"></OrderItemPrice>
    </v-col>
    <v-col cols="4" sm="2" class="d-flex justify-center" v-if="!modify">
      <OrderDetailQuantity :item="item"></OrderDetailQuantity>
    </v-col>
    <v-col cols="4" sm="2" class="d-flex justify-center" v-if="modify">
      <ProductQty
        :product="item.product"
        :item="item"
        @updatePlus="updatePlus()"
        @updateMinus="updateMinus()"
        :modify="true"
      />
    </v-col>
    <v-col cols="4" sm="2" offset-sm="0" class="d-flex justify-center">
      <strong class="gross-total primary--text">
        {{ $n(item.grossTotal, "currency") }}
      </strong>
    </v-col>
    <v-col
      v-if="modify"
      cols="6"
      sm="6"
      class="warning-cnt d-flex align-center mb-3 mb-md-0 flex-start justify-start w-100"
    >
      <v-icon v-if="showError">icon-warning</v-icon>
    </v-col>
    <v-col
      v-if="modify"
      cols="6"
      sm="6"
      class="d-flex align-center mb-3 mb-md-0 flex-end justify-end w-100"
    >
      <v-btn color="primary" @click="openDialogSubs(item)">
        <span>Replace Item</span>
      </v-btn>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.warning-cnt {
  .icon-warning:before {
    color: #ff3333;
  }
}
</style>
<script>
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";
import OrderItemPrice from "@/components/orders/OrderItemPrice.vue";
import ProductQty from "@/components/product/ProductQty.vue";
import ReplaceItem from "@/components/ReplaceItem.vue";

import find from "lodash/find";

export default {
  name: "OrderDetailRow",
  data() {
    return {
      showError: false
    };
  },
  props: {
    item: { type: Object },
    order: { type: Object },
    modify: { type: Boolean, default: false }
  },
  components: { OrderItemPrice, OrderDetailQuantity, ProductQty },
  computed: {
    hasPromo() {
      return (
        this.item.unitPriceStandard &&
        this.item.unitPrice !== this.item.unitPriceStandard
      );
    },
    itemAdjustment() {
      if (
        this.order.orderAdjustmentSummary &&
        this.order.orderAdjustmentSummary.orderAdjustments
      ) {
        let adj = find(
          this.order.orderAdjustmentSummary.orderAdjustments,
          orderAdjustment =>
            orderAdjustment.orderItemId == parseInt(this.item.orderItemId)
        );
        return adj;
      }
      return null;
    },
    itemOption() {
      return this.item.orderItemInfos?.size;
    }
  },
  methods: {
    updatePlus() {
      this.item.quantity++;
      this.item.grossTotal = this.item.grossTotal + this.item.unitPrice;
    },
    updateMinus() {
      this.item.quantity--;
      this.item.grossTotal = this.item.grossTotal - this.item.unitPrice;
    },
    async openDialog(component, properties, fullscreen) {
      var defaultConfig = {
        waitForResult: false,
        fullscreen:
          fullscreen !== undefined
            ? fullscreen
            : this.$vuetify.breakpoint.smAndDown
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = await this.$dialog.show(component, config);
      const result = await this.dialogInstance.wait();
      return result;
    },
    async openDialogSubs(item) {
      window.localStorage.setItem("itemToReplace", JSON.stringify(item));
      let result = await this.openDialog(ReplaceItem, {
        waitForResult: true,
        width: "100%"
      });
      return result;
    }
  },
  created() {
    global.EventBus.$on("apply", () => {
      this.showError = false;
    });
    if (this.order.paymentError) {
      this.showError = true;
    }
    global.EventBus.$on("selectAsReplacement", () => {
      this.dialogInstance.close();
    });
  }
};
</script>
