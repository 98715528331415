<template>
  <div class="login login-container fill-height mt-1 white">
    <v-container fluid fill-height>
      <v-layout class="d-flex flex-column">
        <v-row class="d-flex pa-4 default--text" cols="12">
          <v-col cols="12"><h2>Select a new product as replacement</h2></v-col>
        </v-row>
        <v-row class="d-flex"
          ><v-col
            cols="12"
            md="12"
            lg="12"
            class="order-4 order-md-2 d-flex px-md-4 column mt-1 mt-md-0 search-input-col px-4"
          >
            <div class="flex-grow-1 flex-shrink-0 py-1 py-md-0">
              <SearchInput
                :modify="true"
                class="pr-auto pr-lg-auto"
              /></div></v-col
        ></v-row>
        <v-row class="d-flex" v-if="showSearch"
          ><v-col
            cols="12"
            md="12"
            lg="12"
            class="order-4 order-md-2 d-flex px-md-4 column mt-1 mt-md-0 search-input-col px-4"
          >
            <div class="flex-grow-1 flex-shrink-0 py-1 py-md-0">
              <template>
                <div class="search search-container">
                  <v-container>
                    <div class="primary--text flex-wrap mb-3 mb-sm-0">
                      <h1 class="search-title">
                        Risultati ricerca
                      </h1>
                      <strong class="text-body-1" v-if="count > 0">
                        {{ count }} prodotti trovati per
                        <span class="highlight">"{{ query }}"</span> per la
                        fascia oraria e il punto vendita selezionati
                      </strong>
                    </div>
                    <p class="subtitle-1" v-if="filter">in {{ filter }}</p>
                    <p class="subtitle-2" v-if="realSearch">
                      Avevi cercato
                      <a class="real_search">{{ realSearch }}</a>
                      ma non ha prodotto risultati.
                    </p>
                  </v-container>
                  <v-container>
                    <!-- <div> -->
                    <ProductListGrid
                      :modify="true"
                      :query="query"
                      :barcode="barcode"
                      :parentCategoryId="parent_category_id"
                      :parentProductId="parent_product_id"
                      :key="key"
                      mode="search"
                      @productsCount="updateCount"
                      @searchedText="updateRealSearch"
                      hideFilterString="Main Term"
                      itemListName="Search"
                    />
                  </v-container>
                </div>
              </template></div></v-col
        ></v-row>
      </v-layout>
    </v-container>
  </div>
</template>

<style lang="scss">
.login-container {
  .default--text {
    a {
      color: var(--v-default-base) !important;
      font-weight: bold !important;
    }
  }
}
</style>

<script>
// import ResponseMessage from "@/components/ResponseMessage";
// import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";

import { mapActions, mapGetters } from "vuex";

import SocialService from "~/service/socialService";
import SearchInput from "@/components/navigation/SearchInput.vue";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
// import SecurityService from "~/service/securityService";

import {
  requiredValue,
  isPasswordMinimumLength
} from "~/validator/validationRules";

export default {
  name: "ReplaceItem",
  props: {
    hideLogo: { type: Boolean, required: false, default: false },
    hideRegistration: { type: Boolean, required: false, default: false },
    redirectParams: { type: Object, required: false }
  },
  mixins: [categoryMixins, deliveryReactive],
  components: { SearchInput, ProductListGrid },
  data() {
    return {
      loading: null,
      loadingCardCode: null,
      showPassword: false,
      showSearch: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null,
      enableRegistration: process.env.VUE_APP_ENABLE_REGISTRATION == "true",
      key: 0,
      count: -1,
      query: null,
      currentQuery: null,
      barcode: null,
      parent_category_id: null,
      realSearch: null
    };
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    alternativeSearch(currentQuery) {
      this.currentQuery = currentQuery;
      this.showSearch = true;
    },
    doFBLogin() {
      var _this = this;
      window.facebookConnectPlugin.login(
        ["public_profile", "email"],
        function(userData) {
          SocialService.facebookLoginConnect(
            userData.authResponse.accessToken,
            true
          ).then(
            function(data) {
              if (data.user.login && data.user.login != "") {
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl = "/dashboard";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
              } else {
                //NUOVO UTENTE
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl =
                    "/registration-nocard?social_login=facebook";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
                // SecurityService.refreshLogin(
                //   data.socialRedirectUrl.indexOf("registration") >= 0
                // ).then(
                //   function() {
                //     this.closeLogin();
                //   },
                //   function() {
                //     this.closeLogin();
                //   }
                // );
              }
            },
            function(error) {
              console.log(error);
            }
          );
        },
        function(error) {
          console.log(error);
        }
      );
    },
    facebookLogin() {
      if (this.isCordova) {
        this.doFBLogin();
      } else {
        let redirectBaseUrl = `${window.location.protocol}//${
          window.location.hostname
        }${
          window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : ""
        }`;
        SocialService.openFacebookLoginPage(redirectBaseUrl);
      }
    },
    socialLoginGoogle() {
      if (this.isCordova) {
        let redirectBaseUrl =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : "");

        let url =
          "https://accounts.google.com/o/oauth2/auth?client_id=" +
          global.config.googleClientId +
          "&redirect_uri=" +
          redirectBaseUrl +
          "/ebsn/api/social-redir/google&prompt=consent&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

        SocialService.handleGoogleOnApp(url, "registration-nocard");
      } else {
        SocialService.openGoogleLoginPage();
      }
      this.$emit("submit", false);
    },
    scanCard() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loadingCardCode = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                this.loadingCardCode = false;
                // todo get card code and go to registration
              }
            },
            error => {
              this.loadingCardCode = false;
              console.error("Scanning failed", error);
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              formats:
                this.formats ||
                "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    async handleSubmit() {
      let _this = this;
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (_this.password.length > 0) {
        try {
          _this.loading = true;
          _this.response = {};
          await _this.doLogin({
            email: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe,
            token: token
          });
          if (_this.isAuthenticated) {
            global.EventBus.$emit("login");
            _this.$emit("loggedIn", true);
          }
          this.$emit("submit", true);
          if (_this.redirectParams) {
            _this.$router.push({
              name: _this.redirectParams.name,
              path: _this.redirectParams.path,
              params: _this.redirectParams.params
            });
          }
          this.$recaptchaInstance?.hideBadge();
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    },
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    capitalizeFirstLetter(string) {
      return string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
    },
    updateCount(count) {
      this.count = count;
    },
    updateRealSearch(searchedText) {
      this.realSearch = this.query;
      this.query = searchedText;
    },
    getQueryParams() {
      this.query = this.currentQuery.q;
      this.barcode = this.currentQuery.barcode;
      this.parent_category_id = this.currentQuery.parent_category_id;
      this.parent_product_id = this.currentQuery.product_id;
      this.filter = this.currentQuery.filter;
    },
    reload() {
      this.$store.dispatch("category/resetFilters");
      this.key = this.key + 1;
    }
  },
  async created() {
    // this.getQueryParams();
    //await this.resetFilters("q=" + this.query);
    global.EventBus.$on("alternativeSearch", query => {
      this.alternativeSearch(query);
    });
  },
  watch: {
    async currentQuery() {
      this.getQueryParams();
      this.key = this.key + 1;
      this.realSearch = null;
      this.count = null;
      //await this.resetFilters("q=" + this.query);
      // await this.setCategory({
      //   slug: "search",
      //   query: this.query,
      //   force: true
      // });
    }
  },
  beforeRouteLeave(to, from, next) {
    global.EventBus.$emit("clearSearch");
    next();
  }
};
</script>
