<template>
  <div class="order-detail-table">
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown"
      class="px-5 order-table-header default--text"
      :class="{ 'modify-table': modify }"
    >
      <v-col cols="6">
        <strong>{{ $t("orders.header.product") }}</strong>
      </v-col>

      <v-col cols="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.unitPrice") }}</strong>
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.qty") }}</strong>
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        <strong>{{ $t("orders.header.totalPrice") }}</strong>
      </v-col>
    </v-row>

    <v-list v-for="suborder in suborders" v-bind:key="suborder.suborderId">
      <v-list-item
        v-for="item in suborder.suborderItems"
        v-bind:key="item.itemId"
        class="py-4"
      >
        <order-detail-row :modify="modify" :item="item" :order="order" />
      </v-list-item>
    </v-list>
    <v-list v-for="suborder in testSuborders" v-bind:key="suborder.suborderId">
      <v-list-item
        v-for="item in suborder.suborderItems"
        v-bind:key="item.itemId"
        :to="{ name: 'Product', params: { slug: item.product.slug } }"
        class="py-4"
      >
        <order-detail-row :item="item" :order="order" />
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.vuedl-layout__closeBtn {
  color: var(--v-default-base);
}
.order-detail-table {
  .weight-unit {
    font-size: 10px;
  }
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-lighten1);
  }

  .price {
    .old-price,
    .weight-unit,
    .price-um {
      font-size: 10px;
    }
    .cur-price {
      font-size: 18px;
      font-weight: 400;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      .cur-price {
        font-size: 14px;
      }
    }
  }
  .qty-box {
    border: 1px solid var(--v-grey-lighten1);
    border-radius: $border-radius-root;
    background: $white;
    color: var(--v-default-base);
    padding: 10px 15px;
    min-width: 120px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto;
      width: 100%;
    }
  }

  .gross-total {
    font-size: 18px;
  }
  .modify-table {
    color: var(--v-default-base);
  }
}
</style>
<script>
import OrderDetailRow from "@/components/orders/OrderDetailRow.vue";
import AnalyticsService from "~/service/analyticsService";
import { mapGetters } from "vuex";

export default {
  name: "OrderDetailTable",
  props: {
    order: { type: Object },
    suborders: { type: Array },
    testSuborders: { type: Array },
    delivered: { type: Boolean },
    modify: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrderDetailRow
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    })
  },
  mounted() {
    for (const subOrder of this.suborders) {
      AnalyticsService.viewProducts(subOrder.suborderItems, "Order", 0);
    }
  },
  created() {
    // var _this = this;
    global.EventBus.$on("selectAsReplacement", product => {
      let itemToReplace = JSON.parse(
        window.localStorage.getItem("itemToReplace")
      );
      for (var k = 0; k < this.suborders.length; k++) {
        for (var j = 0; j < this.suborders[k].suborderItems.length; j++) {
          let suborderItem = this.suborders[k].suborderItems[j];
          if (
            suborderItem.product.productId == itemToReplace.product.productId
          ) {
            // suborderItem = this.getItem({
            //   product: product
            // });
            global.EventBus.$emit("closeModal", false);
            suborderItem.product = product;
            suborderItem.unitPrice = product.price;
            suborderItem.quantity = 1;
            suborderItem.grossTotal = product.price;
            break;
          }
        }
      }
    });
  }
};
</script>
