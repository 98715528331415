<template>
  <div>
    <v-card elevation="0" class="order-detail-info" v-if="order">
      <v-row no-gutters align="center" class="px-4">
        <v-col cols="12" sm="6">
          <v-row
            no-gutters
            class="text-body-2 default--text font-weight-bold my-5"
          >
            <v-col cols="6" md="4">
              <span>{{ $t("orders.header.orderNumber") }}</span>
            </v-col>
            <v-col cols="6" md="3">
              <span v-if="!modify">{{ $t("orders.header.status") }}</span>
            </v-col>
            <v-col cols="12" md="5"> </v-col>
          </v-row>
          <v-row no-gutters class="text-body-1 font-weight-bold my-5">
            <v-col cols="6" md="4">
              <span class="primary--text">#{{ order.orderId }}</span>
            </v-col>
            <v-col cols="6" md="3" v-if="!order.orderStatusId == 3">
              <OrderStatusBadge v-bind:order="order" class="default--text" />
            </v-col>
            <v-col cols="6" md="3" v-if="order.orderStatusId == 3 && !modify">
              <template>
                <v-tooltip right content-class="order-status-badge-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-flex align-center font-weight-bold">
                      Rescheduled
                      <v-icon
                        color="primary"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                        >$info</v-icon
                      >
                    </span>
                  </template>
                  <span>Your order has been rescheduled on 03/07/2024</span>
                </v-tooltip>
              </template>
              <span class="my-2 d-block font-weight-bold"
                >Before: 31/05/2024</span
              >
              <span class="my-2 d-block font-weight-bold">Now: 03/07/2024</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="actions d-flex justify-end">
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-center justify-sm-end pa-0 align-center mt-5"
          >
            <OrderQrCode
              v-if="order.orderInfos.PIN_CODE"
              :codeValue="order.orderInfos.PIN_CODE"
              :width="40"
              :height="40"
            />

            <div
              class="d-flex flex-column justify-center align-center mr-5"
              v-if="order.isEditable"
            >
              <v-btn
                @click.stop.prevent="editOrder(order.orderId)"
                class="primary white--text"
                depressed
              >
                <v-icon>$edit</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.edit")
              }}</span>
            </div>

            <div
              class="d-flex flex-column justify-center align-center mr-5"
              v-if="order.isPayable"
            >
              <v-btn
                @click.stop.prevent="showPayment"
                class="primary white--text"
                depressed
              >
                <v-icon>$creditcard</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.retryPayment")
              }}</span>
            </div>

            <div class="d-flex flex-column justify-center align-center mr-5">
              <v-btn
                @click.stop.prevent="addAllToCart(order.orderId)"
                class="primary white--text"
                depressed
              >
                <v-icon>$cart</v-icon>
              </v-btn>
              <span class="primary--text text-center text-nowrap small">{{
                $t("orders.buttons.reorderOrder")
              }}</span>
            </div>

            <div class="d-flex flex-column justify-center align-center mr-5">
              <v-btn @click="printOrder" class="primary white--text" depressed>
                <v-icon>$print</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.print")
              }}</span>
            </div>
            <div
              class="d-flex flex-column justify-center align-center  ml-4"
              v-if="
                isCordova &&
                  (order.orderStatusId == 8 || order.orderStatusId == 10)
              "
            >
              <v-btn
                @click.stop.prevent="addAllToCart(order.orderId)"
                class="primary white--text"
                v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
              >
                <v-icon>$cart</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.reorder")
              }}</span>
            </div>

            <div
              class="d-flex flex-column justify-center align-center"
              v-if="order.isDeletable && !modify"
            >
              <v-btn
                color="error"
                @click.stop.prevent="deleteOrder(order.orderId)"
                depressed
              >
                <v-icon>$delete</v-icon>
              </v-btn>
              <span class="error--text text-center small text-nowrap">
                {{ $t("orders.button.delete") }}
              </span>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
    <!-- <v-card elevation="0" class="order-detail-info" v-else>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="6">
          <v-row
            no-gutters
            class="text-body-2 default--text font-weight-bold my-5"
          >
            <v-col cols="6" md="4">
              <span>{{ $t("orders.header.orderNumber") }}</span>
            </v-col>
            <v-col cols="6" md="3">
              <span>{{ $t("orders.header.status") }}</span>
            </v-col>
            <v-col cols="12" md="5"> </v-col>
          </v-row>
          <v-row no-gutters class="text-body-1 font-weight-bold my-5">
            <v-col cols="6" md="4">
              <span class="primary--text">#{{ testOrder.orderId }}</span>
            </v-col>
            <v-col cols="6" md="3" v-if="!order.isTest">
              <OrderStatusBadge
                v-bind:order="testOrder"
                class="default--text"
              />
            </v-col>
            <v-col cols="6" md="3" v-if="testOrder.isTest == true">
              <template>
                <v-tooltip right content-class="order-status-badge-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-flex align-center font-weight-bold">
                      rescheduled
                      <v-icon
                        color="primary"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                        >$info</v-icon
                      >
                    </span>
                  </template>
                  <span
                    >Il tuo ordine è stato rescheduled per il 03/07/2024</span
                  >
                </v-tooltip>
              </template>
              <span class="my-2 d-block font-weight-bold"
                >Prima: 31/05/2024</span
              >
              <span class="my-2 d-block font-weight-bold"
                >Adesso: 03/07/2024</span
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="actions d-flex justify-end">
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-center justify-sm-end pa-0 align-center mt-5"
          >
            <OrderQrCode
              v-if="testOrder.orderInfos.PIN_CODE"
              :codeValue="testOrder.orderInfos.PIN_CODE"
              :width="40"
              :height="40"
            />

            <div
              class="d-flex flex-column justify-center align-center mr-5"
              v-if="testOrder.isEditable"
            >
              <v-btn
                @click.stop.prevent="editOrder(testOrder.orderId)"
                class="primary white--text"
                depressed
              >
                <v-icon>$edit</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.edit")
              }}</span>
            </div>

            <div
              class="d-flex flex-column justify-center align-center mr-5"
              v-if="testOrder.isPayable"
            >
              <v-btn
                @click.stop.prevent="showPayment"
                class="primary white--text"
                depressed
              >
                <v-icon>$creditcard</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.retryPayment")
              }}</span>
            </div>

            <div class="d-flex flex-column justify-center align-center mr-5">
              <v-btn
                @click.stop.prevent="addAllToCart(testOrder.orderId)"
                class="primary white--text"
                depressed
              >
                <v-icon>$cart</v-icon>
              </v-btn>
              <span class="primary--text text-center text-nowrap small">{{
                $t("orders.buttons.reorderOrder")
              }}</span>
            </div>

            <div class="d-flex flex-column justify-center align-center mr-5">
              <v-btn @click="printOrder" class="primary white--text" depressed>
                <v-icon>$print</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.print")
              }}</span>
            </div>
            <div
              class="d-flex flex-column justify-center align-center  ml-4"
              v-if="
                isCordova &&
                  (testOrder.orderStatusId == 8 ||
                    testOrder.orderStatusId == 10)
              "
            >
              <v-btn
                @click.stop.prevent="addAllToCart(testOrder.orderId)"
                class="primary white--text"
                v-if="
                  testOrder.orderStatusId == 8 || testOrder.orderStatusId == 10
                "
              >
                <v-icon>$cart</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.reorder")
              }}</span>
            </div>

            <div
              class="d-flex flex-column justify-center align-center"
              v-if="order.isDeletable"
            >
              <v-btn
                color="error"
                @click.stop.prevent="deleteOrder(testOrder.orderId)"
                depressed
              >
                <v-icon>$delete</v-icon>
              </v-btn>
              <span class="error--text text-center small text-nowrap">
                {{ $t("orders.button.delete") }}
              </span>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </v-card> -->
  </div>
</template>
<style lang="scss">
.order-detail-info {
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import OrderQrCode from "./OrderQrCode.vue";
import downloadDocumentMixin from "~/mixins/downloadDocument";
export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderQrCode },
  props: {
    order: {
      type: Object
    },
    modify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // testOrder: {
      //   isTest: true,
      //   orderId: 171086711111111,
      //   orderStatusId: 10,
      //   orderTypeId: 1,
      //   orderStatusDescr: "Annullato",
      //   addDate: "2024-02-09T09:38:00Z",
      //   riderUserId: "",
      //   user: {
      //     userId: 200330,
      //     login: ""
      //   },
      //   timeslot: {
      //     timeslotId: 150,
      //     name: "20:00",
      //     date: "2024-02-13",
      //     beginTime: "8:00:00 PM",
      //     endTime: "10:00:00 PM"
      //   },
      //   shippingAddress: {
      //     addressId: 148326441,
      //     addressTypeId: 2,
      //     addressType: "home",
      //     deliveryServiceId: 2,
      //     zoneId: 1000034,
      //     active: 1,
      //     deliveryAddressId: 148326441,
      //     shippingAddressId: 148326441,
      //     addressName: "PATRONATO PORTA VENEZIA",
      //     address1: "Via Giuseppe Baretti",
      //     address2: "",
      //     addressNumber: "1",
      //     city: "Milano",
      //     postalcode: "20122",
      //     doorbellName: "",
      //     province: "MI",
      //     apartmentNumber: "2",
      //     maxDistance: 0.4,
      //     latitude: 45.47163949999999,
      //     longitude: 9.2048765
      //   },
      //   deliveryAddress: {
      //     addressId: 148326441,
      //     addressTypeId: 2,
      //     addressType: "home",
      //     deliveryServiceId: 2,
      //     zoneId: 1000034,
      //     active: 1,
      //     deliveryAddressId: 148326441,
      //     shippingAddressId: 148326441,
      //     addressName: "PATRONATO PORTA VENEZIA",
      //     address1: "Via Giuseppe Baretti",
      //     address2: "",
      //     addressNumber: "1",
      //     city: "Milano",
      //     postalcode: "20122",
      //     doorbellName: "",
      //     province: "MI",
      //     apartmentNumber: "2",
      //     maxDistance: 0.4,
      //     latitude: 45.47163949999999,
      //     longitude: 9.2048765
      //   },
      //   homeDelivery: true,
      //   warehouse: {
      //     warehouseId: 43,
      //     name: "Savona"
      //   },
      //   orderInfos: {},
      //   totalPrice: 89.1,
      //   totalPriceVariable: 0,
      //   netTotal: 89.1,
      //   deliveryFee: 0,
      //   packageTotal: 0,
      //   giftTotal: 0,
      //   refundTotal: 0,
      //   taxTotal: 0,
      //   grossTotal: 89.1,
      //   deliveredNetTotal: 89.1,
      //   deliveredGrossTotal: 0,
      //   deliveredGiftTotal: 0,
      //   deliveredRefundTotal: 0,
      //   deliveredPackageTotal: 0,
      //   deliveredDeliveryFee: 0,
      //   paymentTypeId: 8,
      //   isEditable: false,
      //   isDeletable: false,
      //   isParkingEnabled: false,
      //   isPayable: false,
      //   isRefundable: false,
      //   suborders: [
      //     {
      //       suborderId: 149138272,
      //       grossTotal: 89.1,
      //       suborderInfos: {},
      //       suborderItems: [
      //         {
      //           orderItemId: "171018594",
      //           quantity: 9,
      //           weight: 0,
      //           deliveredQuantity: 0,
      //           deliveredWeight: 0,
      //           deliveredGrossTotal: 0,
      //           grossTotal: 89.1,
      //           unitPrice: 9.9,
      //           product: {
      //             available: 0,
      //             productId: 148000002,
      //             codInt: "148000002",
      //             codVar: "1",
      //             code: "148000002",
      //             codeVariant: "1",
      //             name: "Offerta TELEFONIA 1",
      //             shortDescr: "Descrizione breve dell'offerta Telefonia 1",
      //             description: "",
      //             pubState: 1,
      //             ivaCategory: {
      //               ivaCategoryId: 23,
      //               code: "22-N",
      //               descr: "",
      //               ivaPct: "0.22"
      //             },
      //             price: 9.9,
      //             priceId: 45504577,
      //             priceListName: "Home Delivery Assago",
      //             priceListId: 4,
      //             priceDisplay: 9.9,
      //             priceUnitDisplay: "pz",
      //             slug: "148000002_offerta-telefonia-1",
      //             categoryId: -1,
      //             mediaURL:
      //               "https://www.tigros.it/photo2/2020/05/27/0/main/thumb/foto-telefonia_1.png",
      //             mediaURLMedium:
      //               "https://www.tigros.it/photo2/2020/05/27/0/main/photo/foto-telefonia_1.png",
      //             dayLock: "",
      //             vendor: {
      //               vendorId: 0
      //             },
      //             productClasses: [
      //               {
      //                 productClassId: 10536,
      //                 productClassGroupId: 10002,
      //                 name: "OFFERTA DEL MESE",
      //                 description: "2",
      //                 iconSource:
      //                   "/img_layout/product_classes/offerta-mese.svg",
      //                 productClassTypeId: 2
      //               }
      //             ],
      //             selectOptions: [],
      //             productInfos: {
      //               MINACQ: "1",
      //               WEB_ENABLED: "1",
      //               CHECKED: "true",
      //               TIPOLOGIA: "Pezzo"
      //             },
      //             hasDetails: false
      //           },
      //           orderItemInfos: {}
      //         }
      //       ]
      //     }
      //   ]
      // }
    };
  },
  computed: {
    receiptUrl() {
      let documentTypeId = 12;
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=${documentTypeId}&type=pdf&order_id=${orderId}`;
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
